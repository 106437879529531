// @flow
import * as React from 'react'
import * as actions from '../../actions'
import { useStaticQuery, graphql } from 'gatsby'

type Item = {
  label: string
  link: string
  children?: Item[]
}

type Output = [null | Item, Item[], (e: Event, item: Item) => void, () => void]

export default function useItems(defaultBack: (...args: any[]) => void): Output {
  const gq = useStaticQuery(graphql`
    query SidebarInspirationQuery {
      sidebar {
        inspiration
      }
    }
  `)
  const itemList: Item[] = gq.sidebar.inspiration
  const [activeItem, setActiveItem] = React.useState<null | Item>(null)
  const subItems: Item[] = React.useMemo(() => {
    let items = activeItem && activeItem.children ? activeItem.children : itemList
    return items
  }, [activeItem])

  const handleItemClick = React.useCallback(
    (e: Event, item: Item) => {
      let label = 'Inspiration > '
      if (activeItem) label += activeItem.label + ' > '
      label += item.label
      actions.clickItem(label)
      if (item.children) {
        e.preventDefault()
        setActiveItem(item)
      }
    },
    [activeItem]
  )

  const handleBackClick = React.useCallback(() => {
    let label = 'Inspiration > '
    if (activeItem) label += activeItem.label + ' > '
    label += 'zurück'
    actions.clickItem(label)
    if (activeItem) setActiveItem(null)
    else defaultBack()
  }, [activeItem])

  return [activeItem, subItems, handleItemClick, handleBackClick]
}
