// @flow
import * as React from 'react'
import LinkList from '../LinkList'
import useItems from './hooks/useItems'

type Props = {
  onClose: () => void
}

export default function InspirationDropdown(props: Props) {
  const [activeItem, subItems, handleItemClick, onBackClick] = useItems(props.onClose)

  return (
    <LinkList
      title={activeItem ? activeItem.label : 'Inspiration'}
      onBackClick={onBackClick}
      onItemClick={handleItemClick}
      items={subItems}
    />
  )
}
